<template>
  <section class="user-subscription">
    <div
      v-if="hasSubscription"
      class="user-subscription__content"
    >
      <div class="user-subscription__item">
        <span>
          {{ $t('userAccount.subscription-mode') }}
        </span>
        <span class="user-subscription__item-value">
          {{ subscription.productName }}
        </span>
      </div>
      <div class="user-subscription__item">
        <span>
          {{ $t('userAccount.subscription-status') }}
        </span>
        <span
          :class="[
            'user-subscription__item-value',
            'user-subscription__item-active'
          ]"
        >
          {{ $t('userAccount.subscription-status.active') }}
        </span>
      </div>
      <div class="user-subscription__item">
        <span>
          {{ $t('userAccount.next-payment') }}
        </span>
        <span
          class="user-subscription__item-value"
          v-html="nextBillingText"
        />
      </div>
      <div class="user-subscription__item">
        <span>
          {{ $t('userAccount.payment-method') }}
        </span>
        <span class="user-subscription__item-value">
          {{ subscription.paymentMethod }}
        </span>
      </div>
      <div class="user-subscription__actions">
        <div
          v-if="showUnsubscribeButton"
          class="user-subscription__link"
          @click="openUnsubscribeModal"
        >
          {{ $t('userAccount.cancel-subscription') }}
        </div>
      </div>
    </div>
    <div
      v-else
      class="user-subscription__content"
    >
      <div class="user-subscription__unsubscribed">
        {{ $t('userAccount.subscription-empty') }}
      </div>
    </div>
  </section>
</template>

<script>
import { DateTime } from 'luxon';

export default {
    props: {
        subscription: {
            type: Object,
            default: null,
        },
        showUnsubscribeButton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasSubscription () {
            return this.subscription.subscriptionType === 'pro';
        },
        nextBillingText () {
            const {dueDate, isSubscribed} = this.subscription;
            const formattedDate = DateTime.fromISO(dueDate).toLocaleString();

            if (isSubscribed && dueDate && this.subscription.typeLicense === 'subscription') {
                return formattedDate;
            }
            if ((!isSubscribed && dueDate) || (this.subscription.typeLicense === 'oneTimePayment' && dueDate)) {
                return this.$t('userAccount.subscription-date.cancelled', {date: formattedDate});
            }
            return this.$t('userAccount.subscription-date.never');
        },
    },
    methods: {
        openUnsubscribeModal() {
            this.$emit('open-unsubscribe-modal');
        },
    },
};
</script>

<style src="./UserSubscriptionComponent.scss" lang="scss" />
