<template>
  <div class="modal-content">
    <base-icon
      class="modal-icon"
      size="normal"
      name="warning"
    />
    <h2
      id="modal-title"
      class="modal-title"
    >
      {{ title }}
    </h2>
    <p
      id="modal-text"
      class="modal-text"
    >
      {{ text }}
    </p>
    <div class="modal-buttons">
      <button
        class="modal-button__cancel"
        @click="closeModal"
      >
        {{ $t('userAccount.cancel-sub-modal.button-cancel') }}
      </button>
      <button
        class="modal-button__accept"
        :disabled="loadingButton"
        @click="actionModal"
      >
        {{ $t('userAccount.cancel-sub-modal.button-ok') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
    components: {
        BaseIcon: () =>
            import(/* webpackChunkName: "BaseIcon"*/ '@RepoComponent/BaseIcon/BaseIcon'),
    },
    props: {
        loadingButton: {
            type: Boolean,
            default: false,
        },
        isFromDeleteButton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        title() {
            return this.isFromDeleteButton ? this.$t('userAccount.cancel-sub-modal.cancel_title') : this.$t('userAccount.cancel-sub-modal.title');
        },
        text() {
            return this.isFromDeleteButton ? this.$t('userAccount.cancel-sub-modal.cancel_text') : this.$t('userAccount.cancel-sub-modal.text');
        },
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        actionModal() {
            this.$emit('action');
        },
    },
};
</script>

<style src="./CancelSubscriptionConfirmComponent.scss" lang="scss" scoped />