export const nickNameStates = {
    SUCCESS: 'success',
    ERROR: 'error',
    DEFAULT: 'default',
    LOADING: 'loading',
    EDITING: 'editing',
};

export const nickNameStatesIcons = {
    [nickNameStates.SUCCESS]: {
        wrapperClass: 'user-details__input--success',
        icon: require('images/success-icon.svg'),
        dataTestId: 'success-icon',
        alt: 'success icon',
    },
    [nickNameStates.ERROR]: {
        wrapperClass: 'user-details__input--error',
        icon: require('images/edit-error-icon.svg'),
        dataTestId: 'error-icon',
        alt: 'error icon',
    },
    [nickNameStates.LOADING]: {
        wrapperClass: 'user-details__input--loading',
        icon: require('images/spinner.svg'),
        dataTestId: 'spinner-icon',
        alt: 'loading icon',
    },
    [nickNameStates.EDITING]: {
        wrapperClass: 'user-details__input--default',
        icon: false,
    },
    [nickNameStates.DEFAULT]: {
        wrapperClass: 'user-details__input--default',
        icon: require('images/edit-icon.svg'),
        dataTestId: 'edit-icon',
        alt: 'edit icon',
    },
};

export const nickNameFormat = /^([a-zA-Z0-9]){4,40}$/;

export const nickNameErrorDelay = 3000;

export const maxNickNameChangesPerDay = 1;

export const nickNameCookieName = 'updated-nickname';