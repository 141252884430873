<template>
  <section class="user-social-logins">
    <div class="user-social-logins__list">
      <base-icon
        v-for="socialLogin in socialLogins"
        :key="socialLogin.name"
        :name="socialLogin.name"
        :background="socialBackgrounds(socialLogin)"
        :class="baseIconClass(socialLogin)"
        size="normal"
      >
        <template #label>
          {{ socialLogin.name }}
        </template>
      </base-icon>
    </div>
  </section>
</template>

<script>
import { classNames } from '@/utils';
import {userDomain, SOCIAL_LOGIN_NAME, SOCIAL_LOGIN_STATUS} from '@/domain/user';

const SOCIAL_ICON_BACKGROUND = {
    [SOCIAL_LOGIN_NAME.DISCORD]: '#7289DA',
    [SOCIAL_LOGIN_NAME.TWITCH]: '#6441a5',
    [SOCIAL_LOGIN_NAME.GOOGLE]: '#FFF',
    [SOCIAL_LOGIN_NAME.APPLE]: '#FFF',
};

export default {
    components: {
        BaseIcon: () => import(/* webpackChunkName: "BaseIcon" */ '@RepoComponent/BaseIcon/BaseIcon'),
    },
    props: {
        socialLogins: {
            type: Array,
            required: true,
        },
    },
    methods: {
        baseIconClass(social) {
            return classNames({
                [SOCIAL_LOGIN_STATUS.ACTIVE]: userDomain.isSocialActive(social),
                [SOCIAL_LOGIN_STATUS.INACTIVE]: !userDomain.isSocialActive(social),
                [SOCIAL_LOGIN_NAME.APPLE]: userDomain.isApple(social),
                [SOCIAL_LOGIN_NAME.TWITCH]: userDomain.isTwitch(social),
                [SOCIAL_LOGIN_NAME.DISCORD]: userDomain.isDiscord(social),
                [SOCIAL_LOGIN_NAME.GOOGLE]: userDomain.isGoogle(social),
            });
        },
        socialBackgrounds(social) {
            if (userDomain.isSocialActive(social)) {
                return SOCIAL_ICON_BACKGROUND[social.name];
            }

            return 'black';
        },
    },
};
</script>

<style src="./UserSocialLoginsComponent.scss" lang="scss" />