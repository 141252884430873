<template>
  <section class="data-collection">
    <BaseToggle
      class="data-collection__toggle"
      data-testid="data-collection-toggle"
      :is-checked="dataCollectionConsent"
      has-external-value
      @onClick="dataCollectionChecked"
    >
      <span class="data-collection__toggle--title">
        {{ $t('accounts.user-account.data-collection-toggle') }}
      </span>
    </BaseToggle>
    <div class="data-collection__description">
      {{ $t('accounts.user-account.data-collection-description') }}
      <a
        :href="termsUrl"
        target="_blank"
      >
        {{ $t('accounts.user-account.data-collection-description-link') }}
      </a>
    </div>
  </section>
</template>

<script>
import BaseToggle from '@RepoComponent/BaseToggle/BaseToggle.vue';

export default {
    components: {
        BaseToggle,
    },
    props: {
        dataCollectionConsent: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dataCollectionValue: false,
            termsUrl: 'https://www.voicemod.net/privacy/#improvement-projects',
        };
    },
    methods: {
        dataCollectionChecked() {
            if (this.isLoading) {
                return;
            }
            this.$emit('toggleConsent', { dataCollection: !this.dataCollectionConsent });
        },
    },
};
</script>

<style src="./DataCollectionComponent.scss" lang="scss"></style>
