<template>
  <div class="modal-content">
    <base-icon
      class="modal-icon"
      size="normal"
      name="queue-check"
    />
    <h2
      id="modal-title"
      class="modal-title"
    >
      {{ $t('userAccount.cancel-sub-modal-success.title') }}
    </h2>
    <p
      id="modal-text"
      class="modal-text"
    >
      {{ $t('userAccount.cancel-sub-modal-success.text') }}
    </p>
    <div class="modal-buttons">
      <a
        :href="surveyUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="modal-buttons__accept"
        @click="closeModal"
      >
        {{ $t('userAccount.cancel-sub-modal-success.button-ok') }}
      </a>
    </div>
  </div>
</template>

<script>
import { URL_CONSTANTS } from '@/externalURLs';

export default {
    components: {
        BaseIcon: () =>
            import(/* webpackChunkName: "BaseIcon"*/ '@RepoComponent/BaseIcon/BaseIcon'),
    },

    data() {
        return {
            surveyUrl: URL_CONSTANTS.SUBSCRIPTION_CANCELLED_SURVEY,
        };
    },

    methods: {
        closeModal() {
            this.$emit('close', 'success');
        },
    },
};
</script>

<style src="./CancelSubscriptionSuccessComponent.scss" lang="scss" scoped />