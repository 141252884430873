<template>
  <div class="delete-account">
    <base-icon
      class="modal-icon"
      size="normal"
      name="warning"
    />
    <h2
      class="delete-account__title"
    >
      {{ $t('userAccount.cancelModal.title') }}
    </h2>
    <p
      class="delete-account__description"
    >
      {{ $t('userAccount.cancelModal.text') }}
    </p>
    <div class="delete-account__check">
      <div
        data-testid="checkbox"
        @click="toggleCheck"
      >
        <BaseCheckBox
          size="big"
          :is-checked="isChecked"
        />
      </div>
      {{ $t('userAccount.cancelModal.checkbox') }}
    </div>
    <div class="delete-account__actions">
      <button
        class="delete-account__button delete-account__cancel"
        data-testid="cancel-button"
        @click="closeModal"
      >
        {{ $t('userAccount.cancelModal.close') }}
      </button>
      <button
        class="delete-account__button delete-account__accept"
        :class="{ 'delete-account__button--disabled': isButtonDisabled}"
        data-testid="confirm-button"
        @click="actionModal"
      >
        {{ $t('userAccount.cancelModal.cta') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
    components: {
        BaseIcon: () =>
            import(/* webpackChunkName: "BaseIcon"*/ '@RepoComponent/BaseIcon/BaseIcon'),
        BaseCheckBox: () =>
            import(/* webpackChunkName: "BaseCheckBox"*/ '@RepoComponent/BaseCheckBox/BaseCheckBox'),
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isChecked: false,
        };
    },
    computed: {
        isButtonDisabled() {
            return this.isLoading || !this.isChecked;
        },
    },
    methods: {
        toggleCheck(event) {
            event.preventDefault();
            this.isChecked = !this.isChecked;
        },
        closeModal() {
            this.$emit('close');
        },
        actionModal() {
            if (this.isButtonDisabled) {
                return;
            }
            this.$emit('action');
        },
    },
};
</script>

<style src="./DeleteAccountConfirmComponent.scss" lang="scss" />