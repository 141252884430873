<template>
  <div class="BetaProgram">
    <div class="BetaProgram__top">
      <h3 class="BetaProgram__title">
        {{ $t('userAccount.beta.program.name') }}
      </h3>
      <BaseToggle
        class="BetaProgram__toggle"
        :is-checked="isUserInBetaProgram"
        has-external-value
        @onClick="updateConsent"
      >
        <span
          class="BetaProgram__label"
          data-testid="beta-program-label"
          :class="{
            'BetaProgram__label--active': isUserInBetaProgram,
          }"
        >
          {{ toggleStatus }}
        </span>
      </BaseToggle>
    </div>
    <div class="BetaProgram__bottom">
      {{ $t('userAccount.beta.program.toggle.text') }}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import { CookieService } from '@/services/cookie';
import { AccountClient } from '@/services/models/accounts';
import { ACCEPT_BETA_PAYLOAD, DECLINE_BETA_PAYLOAD } from './constants';
import BaseToggle from '@RepoComponent/BaseToggle/BaseToggle.vue';
export default {
    name: 'BetaProgramComponent',
    components: {
        BaseToggle,
    },
    computed: {
        ...mapState('user_info_store', {
            userInfoStore: (state) => state.user_info_store.userInfo,
        }),
        ...mapGetters('user_info_store', [
            'isUserInBetaProgram',
        ]),
        toggleStatus() {
            return this.isUserInBetaProgram
                ? this.$t('userAccount.beta.program.toggle.active')
                : this.$t('userAccount.beta.program.toggle.inactive');
        },
    },
    mounted() {
        this.userId = CookieService.getCookie('userId');
    },
    methods: {
        ...mapActions('user_info_store', [
            'axn_setUserInfo',
        ]),
        async updateConsent() {
            try {
                const payload = this.isUserInBetaProgram ? DECLINE_BETA_PAYLOAD : ACCEPT_BETA_PAYLOAD;
                await AccountClient.updateConsent(this.userId, payload);
                const { data } = await AccountClient.getUserInfo(this.userId);
                this.axn_setUserInfo({ userId: this.userId, ...data });
            } catch (error) {
                Sentry.captureMessage('Cannot update consent in BetaProgram');
                Sentry.captureException(error);
            }
        },
    },
};
</script>

<style lang="scss" src="./BetaProgramComponent.scss" />
