<template>
    <label
        class="base-toggle"
        :class="[
            isDisabled ? '-disabled' : null,
            isDangerAction ? 'base-toggle--danger' : null,
            isSmall ? 'base-toggle--small -text--small' : null,
            isDark ? 'base-toggle--dark' : null,
        ]"
        @click.prevent="emitClick"
    >
        <input
            class="base-toggle__checkbox"
            type="checkbox"
            :checked="checked"
            :disabled="isDisabled"
        >
        <span
            class="base-toggle__checkmark"
            @click.prevent="toggleClick"
        ></span>
        <span
            v-if="defaultSlotExist"
            class="base-toggle__label"
            @click.prevent="labelClick"
        >
            <slot></slot>
        </span>
    </label>
</template>

<script>
    export default {
        name: 'BaseToggle',

        props: {
            isDisabled: {
                type: Boolean,
                default: false,
            },
            isDangerAction: {
                type: Boolean,
                default: false,
            },
            isSmall: {
                type: Boolean,
                default: false,
            },
            isDark: {
                type: Boolean,
                default: false,
            },
            isChecked: {
                type: Boolean,
                default: false,
            },
            hasExternalValue: {
                type: Boolean,
                default: false,
            },
            isReversed: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                simpleCheckedValue: this.isChecked,
            }
        },

        computed: {
            defaultSlotExist() {
                return this.$slots.default;
            },

            checkedWithExternalValue() {
                return !this.hasExternalValue ? this.simpleCheckedValue : this.isChecked;
            },

            checked() {
                return this.isReversed ? !this.checkedWithExternalValue : this.checkedWithExternalValue
            }
        },

        watch: {
            isChecked(newValue) {
                this.simpleCheckedValue = newValue;
            }
        },

        methods: {
            emitClick() {
                !this.hasExternalValue ? this.simpleCheckedValue = !this.simpleCheckedValue : null;
                this.$emit('onClick', !this.checkedWithExternalValue);
            },

            toggleClick() {
                this.$emit('onToggleClick', !this.checkedWithExternalValue);
            },

            labelClick() {
                this.$emit('onLabelClick');
            }
        },

    }
</script>

<style lang="scss" src="./BaseToggle.scss"></style>
