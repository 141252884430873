<template>
  <section class="user-details">
    <div
      class="user-details__section"
      data-testid="username-input"
    >
      <label
        for="nickname"
        class="user-details__input-label"
      >
        {{ $t('userAccount.username') }}
      </label>
      <base-input
        id="nickname"
        :value="nickName"
        :class="nickNameIcon.wrapperClass"
        :is-disabled="isNickNameDisabled"
        @blur="onChangeNickName"
        @input="setNickNameValue"
        @focus="onNickNameFocus"
      >
        <template #icon>
          <img
            v-if="nickNameIcon.icon"
            :src="nickNameIcon.icon"
            :alt="nickNameIcon.alt"
            :data-testId="nickNameIcon.dataTestId"
            class="user-details__icon base-input__icon base-input__icon-right user-details__input-icon"
            @click="focusNickName"
          >
        </template>
      </base-input>
      <div
        v-if="nickNameHasError"
        class="user-details__error"
      >
        {{ nickNameErrorMessage }}
      </div>
    </div>
    <div
      class="user-details__section"
      data-testid="email-input"
    >
      <label
        for="email"
        class="user-details__input-label"
      >
        {{ $t('userAccount.email.title') }}
      </label>
      <base-input
        id="email"
        :value="formattedEmail"
        data-testid="email"
        is-disabled
      >
        <template #icon>
          <button
            class="user-details__button base-input__icon base-input__icon-right"
            data-testid="email-eye-button"
            @click="toggleEmail"
          >
            <img
              v-if="showEmail"
              :src="require('images/eye-invisible-icon.svg')"
              alt="invisible eye icon"
              class="user-details__icon"
              data-testid="gift-eye-invisible"
            >
            <img
              v-else
              :src="require('images/eye-visible-icon.svg')"
              alt="visible eye icon"
              class="user-details__icon"
              data-testid="gift-eye-visible"
            >
          </button>
        </template>
      </base-input>
    </div>
    <div class="user-details__account-creation">
      {{ $t('userAccount.accountCreation') }}
      {{ formattedDate }}
    </div>
  </section>
</template>

<script>
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/vue';
import { maskEmail } from '@/utils';
import { AccountClient } from '@/services/models/accounts';
import { CookieService } from '@/services/cookie';

import {
    nickNameStates,
    nickNameStatesIcons,
    nickNameFormat,
    nickNameErrorDelay,
} from './constants';


export default {
    name: 'UserDetailsComponent',
    components: {
        BaseInput: () => import(/* webpackChunkName: "BaseInput" */ '@RepoComponent/BaseInput/BaseInput'),
    },
    props: {
        userInfo: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            userId: null,
            showEmail: false,
            nickName: '',
            nickNameStatus: nickNameStates.DEFAULT,
            nickNameErrorMessage: '',
        };
    },
    computed: {
        formattedDate() {
            const { createdAt } = this.userInfo;
            return DateTime.fromISO(createdAt).toLocaleString();
        },
        userNickName() {
            return this.userInfo.nickName;
        },
        formattedEmail() {
            return this.showEmail ? this.userInfo.email : maskEmail(this.userInfo.email);
        },
        nickNameIcon() {
            return nickNameStatesIcons[this.nickNameStatus];
        },
        nickNameHasError() {
            return this.nickNameStatus === nickNameStates.ERROR;
        },
        isNickNameDisabled() {
            return ![nickNameStates.DEFAULT, nickNameStates.EDITING].includes(this.nickNameStatus);
        },
        hasNickNameChanged() {
            return this.nickName !== this.userNickName;
        },
    },
    created() {
        this.nickName = this.userInfo.nickName;
    },
    mounted() {
        this.userId = CookieService.getCookie('userId');
        this.nickNameErrors = {
            422: this.$t('userAccount.username.error.characters'),
            409: this.$t('userAccount.username.error.exists'),
            410: this.$t('userAccount.username.limit'),
        };
    },
    methods: {
        toggleEmail() {
            this.showEmail = !this.showEmail;
        },
        onNickNameFocus() {
            this.nickNameStatus = nickNameStates.EDITING;
        },
        focusNickName() {
            document.getElementById('nickname').focus();
        },
        setNickNameValue(nickName) {
            this.nickName = nickName;
        },
        async updateUserInfo() {
            try {
                const { data } = await AccountClient.getUserInfo(this.userId);
                this.$store.dispatch('user_info_store/axn_setUserInfo', { userId: this.userId, ...data });
            } catch (error) {
                this.nickNameStatus = nickNameStates.ERROR;
                Sentry.captureMessage('Can\'t retrieve user information on Edit Username');
                Sentry.captureException(error);
            }
        },
        setNickNameDefaultDelay() {
            setTimeout(() => {
                this.nickNameStatus = nickNameStates.DEFAULT;
                this.nickName = this.userNickName;
            }, nickNameErrorDelay);
        },
        validateNickName(nickName) {
            if (!nickName.match(nickNameFormat)) {
                throw { response: { status: 422 }};
            }
        },
        async onChangeNickName() {
            this.nickNameStatus = nickNameStates.DEFAULT;
            if (!this.hasNickNameChanged) {
                return;
            }
            this.nickNameStatus = nickNameStates.LOADING;
            try {
                this.validateNickName(this.nickName);
                await AccountClient.updateNickName(this.userId, this.nickName);
                await this.updateUserInfo();
                this.nickNameStatus = nickNameStates.SUCCESS;
            } catch (err) {
                const { response } = err;
                this.nickNameStatus = nickNameStates.ERROR;
                if (response.status === 409 && response.data?.description?.includes('change not allowed')) {
                    // should show username limit error
                    this.nickNameErrorMessage = this.nickNameErrors[410];
                } else {
                    this.nickNameErrorMessage = this.nickNameErrors[response.status];
                }
            } finally {
                this.setNickNameDefaultDelay();
            }
        },
    },
};
</script>

<style src="./UserDetailsComponent.scss" lang="scss" />
