<template>
  <div class="modal-content">
    <base-icon
      class="modal-icon"
      size="normal"
      name="warning"
    />
    <h2
      id="modal-title"
      class="modal-title"
    >
      {{ $t('userAccount.cancel-sub-modal-error.title') }}
    </h2>
    <p
      id="modal-text"
      class="modal-text"
    >
      {{ $t('userAccount.cancel-sub-modal-error.text') }}
    </p>
    <div class="modal-buttons">
      <button
        class="modal-button__accept"
        @click="closeModal"
      >
        {{ $t('userAccount.cancel-sub-modal-error.button-ok') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
    components: {
        BaseIcon: () =>
            import(/* webpackChunkName: "BaseIcon"*/ '@RepoComponent/BaseIcon/BaseIcon'),
    },
    destroyed() {
        this.$emit('closed');
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
    },
};
</script>

<style src="./CancelSubscriptionErrorComponent.scss" lang="scss" scoped />