import {
    BETA_CONSENT_KEY,
    BETA_CONSENT_ACCEPTED,
    BETA_CONSENT_DECLINED,
} from '@/domain/user';

export const ACCEPT_BETA_PAYLOAD = {
    status: BETA_CONSENT_ACCEPTED,
    type: BETA_CONSENT_KEY,
};

export const DECLINE_BETA_PAYLOAD = {
    status: BETA_CONSENT_DECLINED,
    type: BETA_CONSENT_KEY,
};