export const maskEmail = (email) => {
    if (typeof email === 'string' && email.match(/@/)) {
        const [emailName, emailDomain] = email.split('@');
        const hiddenName = `${emailName.charAt(0)}*****${emailName.charAt(emailName.length - 1)}`;
        return `${hiddenName}@${emailDomain}`;
    }
    return '';
};

export const classNames = (object = {}) => {
    const truthyKeys = Object.keys(object).filter((key) => !!object[key]);
    return truthyKeys.join(' ');
};