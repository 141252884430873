<template>
  <PageLayout
    v-if="userInfo"
    :title="$t('userAccount.my-account')"
    @logout="userLogout"
  >
    <div class="user-account">
      <div class="user-account__blocks">
        <div class="user-account__block">
          <div class="user-account__details">
            <h3 class="user-account__section-title">
              {{ $t('userAccount.details.title') }}
            </h3>
            <user-details-component :user-info="userInfoStore" />
            <div class="user-account__social">
              <div class="user-account__section-title">
                {{ $t('userAccount.connected-accounts') }}
              </div>
              <user-social-logins-component :social-logins="socialLogins" />
            </div>
          </div>
        </div>
        <div class="user-account__block">
          <div class="user-account__subscription">
            <h3 class="user-account__section-title">
              {{ $t('userAccount.subscription.title') }}
            </h3>
            <user-subscription-component
              :subscription="userInfo.subscription"
              :show-unsubscribe-button="isCancelSubscriptionVisible"
              @open-unsubscribe-modal="openUnsubscribeModal"
            />
          </div>

          <beta-program-component v-if="isBetaToggleVisible" />

          <div
            class="user-account__security"
          >
            <h2 class="user-account__section-title">
              {{ $t('userAccount.security-title') }}
            </h2>
            <DataCollectionComponent
              :data-collection-consent="isDataCollectionConsentAccepted"
              :is-loading="settingDataCollection"
              @toggleConsent="updateConsentStatus"
            />
          </div>
          <div
            class="user-account__delete user-subscription__link"
            @click="openCancelModal"
          >
            {{ $t('userAccount.cancelModal.cta') }}
          </div>
        </div>
      </div>
      <button
        v-if="!isMobile"
        class="user-account__download-button"
        @click="goToDownloadApp"
      >
        {{ $t('userAccount.download') }}
      </button>
      <terms-footer-component class="login-options__terms-footer-component" />
      <p class="user-account__user-id">
        {{ userId }}
      </p>
    </div>
    <modal-component
      v-if="cancelModalStatus"
      @close="closeCancelModal"
    >
      <delete-account-confirm-component
        v-if="CANCEL_MODAL_KEYS.confirm"
        @close="closeCancelModal"
        @action="deleteAccount"
      />
    </modal-component>
    <modal-component
      v-if="unsubscribeModalStatus"
      @close="closeUnsubscribeModal"
    >
      <cancel-subscription-success-component
        v-if="unsubscribeModalStatus === UNSUBSCRIBE_MODAL_KEYS.success"
        @close="closeUnsubscribeModal"
        @closed="updateUserInfo"
      />
      <cancel-subscription-confirm-component
        v-if="unsubscribeModalStatus === UNSUBSCRIBE_MODAL_KEYS.confirm"
        :loading-button="loadingModalButton"
        :is-from-delete-button="isUnsubscribeFromDelete"
        @close="closeUnsubscribeModal"
        @action="cancelSubscription"
      />
      <cancel-subscription-error-component
        v-if="unsubscribeModalStatus === UNSUBSCRIBE_MODAL_KEYS.error"
        @close="closeUnsubscribeModal"
      />
    </modal-component>
  </PageLayout>
</template>

<script>
import { mapState } from 'vuex';
import { AccountClient } from '@/services/models/accounts';
import { CookieService } from '@/services/cookie';
import { userDomain, SUBSCRIPTION_TYPES } from '@/domain/user';
import { mParticleMetric } from '@/metrics';
import userAgentParser from '@voicemod1/user-agent-decoder';
import { sessionServiceDomain } from '@/domain/session';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { ROUTER_PATHS } from '@/router/paths';
import { UNSUBSCRIBE_MODAL_KEYS, CANCEL_MODAL_KEYS, OS_NAMES } from '@/domain/user/constants';
import PageLayout from '@/components/PageLayout/PageLayout';
import UserDetailsComponent from '@/components/UserDetailsComponent/UserDetailsComponent';
import UserSocialLoginsComponent from '@/components/UserSocialLoginsComponent/UserSocialLoginsComponent';
import UserSubscriptionComponent from '@/components/UserSubscriptionComponent/UserSubscriptionComponent';
import DataCollectionComponent from '@/components/DataCollectionComponent/DataCollectionComponent';
import ModalComponent from '@/components/ModalComponent/ModalComponent';
import BetaProgramComponent from '@/components/BetaProgramComponent/BetaProgramComponent';
import CancelSubscriptionConfirmComponent from
'@/components/CancelSubscriptionConfirmComponent/CancelSubscriptionConfirmComponent';
import CancelSubscriptionSuccessComponent from
'@/components/CancelSubscriptionSuccessComponent/CancelSubscriptionSuccessComponent';
import CancelSubscriptionErrorComponent from
'@/components/CancelSubscriptionErrorComponent/CancelSubscriptionErrorComponent';
import TermsFooterComponent from '@/components/TermsFooterComponent/TermsFooterComponent.vue';
import DeleteAccountConfirmComponent from '@/components/DeleteAccountConfirmComponent/DeleteAccountConfirmComponent.vue';

export default {
    components: {
        PageLayout,
        UserDetailsComponent,
        UserSocialLoginsComponent,
        UserSubscriptionComponent,
        ModalComponent,
        CancelSubscriptionConfirmComponent,
        CancelSubscriptionSuccessComponent,
        CancelSubscriptionErrorComponent,
        DataCollectionComponent,
        TermsFooterComponent,
        BetaProgramComponent,
        DeleteAccountConfirmComponent,
    },
    data() {
        return {
            userInfo: null,
            code: null,
            unsubscribeModalStatus: null,
            isUnsubscribeFromDelete: false,
            cancelModalStatus: null,
            isCancelModalLoading: false,
            loadingModalButton: false,
            settingDataCollection: false,
        };
    },
    computed: {
        ...mapState({
            userInfoStore: (state) => state.user_info_store.userInfo,
        }),
        socialLogins() {
            return this.userInfo.loginProviders.filter(
                (provider) => provider.name !== 'email',
            );
        },
        hasValidSession() {
            return CookieService.getCookie('access_token');
        },
        userId() {
            const userIds = [
                CookieService.getCookie('userId'),
                this.userInfoStore?.userId,
                JSON.parse(sessionStorage.getItem('userInfo'))?.userId,
                this.$route.query?.userId,
            ];

            return userIds.find((id) => id) || null;
        },
        isMobile() {
            const userAgent = userAgentParser.parse(navigator.userAgent);
            const osName = userAgent.getOSName();
            return !Object.values(OS_NAMES).includes(osName);
        },
        isProUser() {
            return (
        this.userInfo.subscription?.subscriptionType === SUBSCRIPTION_TYPES.pro
            );
        },
        isBetaToggleVisible() {
            return process.env.VUE_APP_ENVIRONMENT !== 'production' && this.isProUser;
        },
        isDataCollectionConsentAccepted() {
            const dataCollectionConsent = this.userInfo.consents.find(
                (consent) => consent.type === userDomain.CONSENT_NAME.dataCollection,
            );

            return (
        dataCollectionConsent?.status === userDomain.CONSENT_STATUS.ACCEPTED
            );
        },
        marketingConsent() {
            return this.userInfo.consents.find(
                (consent) => consent.type === userDomain.CONSENT_NAME.marketing,
            );
        },
        isCancelSubscriptionVisible() {
            return this.userInfo.subscription && this.userInfo.subscription.subscriptionId && this.userInfo.subscription.isSubscribed;
        },
    },
    created() {
        this.UNSUBSCRIBE_MODAL_KEYS = UNSUBSCRIBE_MODAL_KEYS;
        this.CANCEL_MODAL_KEYS = CANCEL_MODAL_KEYS;
    },
    beforeMount() {
        this.code = this.$route.query.code || null;
        this.$store.dispatch('user_info_store/axn_setShowProfile', false);
    },
    async mounted() {
        if (!this.hasValidSession) {
            this.$router.push({
                path: ROUTER_PATHS.HOME,
                query: {
                    action: LOGIN_ACTIONS_CONSTANTS.ACCOUNT,
                },
            });
            return;
        }

        const hasSubscription = this.userInfo?.subscription?.isSubscribed;
        mParticleMetric.track(mParticleMetric.EVENTS.USER_ACCOUNT_VIEW);

        if (!hasSubscription) {
            const { data: userInfo } = await AccountClient.getUserInfo(this.userId);
            this.setUserInfo(userInfo);
            return;
        }

        const userInfo = await this.getUserInfo();
        this.setUserInfo(userInfo);
    },
    methods: {
        setUserInfo(userInfo) {
            const serializedUserInfo = userDomain.serializer(userInfo);
            this.userInfo = { userId: this.userId, ...serializedUserInfo };
            this.$store.dispatch('user_info_store/axn_setUserInfo', {
                userId: this.userId,
                ...serializedUserInfo,
            });
            sessionStorage.setItem(
                'userInfo',
                JSON.stringify({ userId: this.userId, ...serializedUserInfo }),
            );
            CookieService.setCookie('userId', this.userId);
        },
        userLogout() {
            sessionServiceDomain.userLogout();
            this.$router.push({
                path: ROUTER_PATHS.HOME,
                query: {
                    action: LOGIN_ACTIONS_CONSTANTS.ACCOUNT,
                },
            });
        },
        async getUserInfo() {
            if (this.userInfoStore?.userId) {
                return this.userInfoStore;
            }
            const sessionUserInfo = JSON.parse(sessionStorage.getItem('userInfo'));

            if (sessionUserInfo?.userId) {
                return sessionUserInfo;
            }

            const { data } = await AccountClient.getUserInfo(this.userId);

            return { userId: this.userId, ...data };
        },
        openUnsubscribeModal({ isFromDelete } = { isFromDelete: false }) {
            this.isUnsubscribeFromDelete = isFromDelete;
            this.unsubscribeModalStatus = UNSUBSCRIBE_MODAL_KEYS.confirm;
            mParticleMetric.track(mParticleMetric.EVENTS.CANCEL_SUBSCRIPTION_CLICKED);
        },
        openCancelModal() {
            if (this.isCancelSubscriptionVisible) {
                this.openUnsubscribeModal({ isFromDelete: true });
                return;
            }
            this.isUnsubscribeFromDelete = false;
            this.cancelModalStatus = CANCEL_MODAL_KEYS.confirm;
        },
        closeCancelModal() {
            this.cancelModalStatus = null;
        },
        closeUnsubscribeModal(param) {
            this.unsubscribeModalStatus = null;

            if (param === UNSUBSCRIBE_MODAL_KEYS.success) {
                this.updateUserInfo();
            }
        },
        async cancelSubscription() {
            const { subscriptionId, source } = this.userInfo.subscription;
            try {
                this.loadingModalButton = true;
                await AccountClient.cancelUserSubscription(
                    this.userId,
                    subscriptionId,
                    source,
                );
                this.loadingModalButton = false;
                mParticleMetric.track(mParticleMetric.EVENTS.SUBSCRIPTION_CANCELED);
                this.unsubscribeModalStatus = UNSUBSCRIBE_MODAL_KEYS.success;
            } catch (error) {
                this.loadingModalButton = false;
                this.unsubscribeModalStatus = UNSUBSCRIBE_MODAL_KEYS.error;
            }
        },
        async deleteAccount () {
            try {
                this.isCancelModalLoading = true;
                await AccountClient.deleteAccount(this.userId);
                this.isCancelModalLoading = false;
                mParticleMetric.track(mParticleMetric.EVENTS.ACCOUNT_DELETED);
                sessionServiceDomain.userLogout();
                this.$router.push(ROUTER_PATHS.DELETED_ACCOUNT);
            } catch (error) {
                mParticleMetric.track(mParticleMetric.EVENTS.ACCOUNTS_WEB_UNEXPECTED_ERROR_OCCURRED, {
                    login_error_reason: 'delete_account_failed',
                });
                this.$router.push(ROUTER_PATHS.ACCOUNT_ACTIVATED_ERROR);
            }
        },
        async updateUserInfo() {
            const { data: userInfo } = await AccountClient.getUserInfo(this.userId);
            this.setUserInfo(userInfo);
        },
        async updateConsentStatus(consent) {
            this.settingDataCollection = true;
            const [userConsent] = userDomain.generateConsent(consent);
            await AccountClient.updateUserConsents(this.userId, [userConsent]);
            this.setUserConsent(userConsent);
            this.settingDataCollection = false;
        },
        setUserConsent(userConsent) {
            const myNewUserInfo = this.userInfo;
            myNewUserInfo.consents = myNewUserInfo.consents.map((consent) => {
                if (consent.type === userConsent.type) {
                    return userConsent;
                }
                return consent;
            });

            const consentInUserInfo = myNewUserInfo.consents.find((consent) => consent.type === userConsent.type);
            if (!consentInUserInfo) {
                myNewUserInfo.consents.push(userConsent);
            }

            this.userInfo = myNewUserInfo;
        },

        goToDownloadApp() {
            const userAgent = userAgentParser.parse(navigator.userAgent);
            const osName = userAgent.getOSName();

            if (osName === OS_NAMES.mac) {
                this.$router.push({
                    path: ROUTER_PATHS.HOME,
                    query: {
                        action: LOGIN_ACTIONS_CONSTANTS.DOWNLOAD_FLAGSHIP,
                    },
                });

                return;
            }

            this.$router.push({
                path: ROUTER_PATHS.HOME,
                query: {
                    action: LOGIN_ACTIONS_CONSTANTS.DOWNLOAD,
                },
            });
        },
    },
};
</script>

<style src="./UserAccountView.scss" lang="scss" />
